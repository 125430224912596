import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/WQ.css'

import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import foto6 from "../images/fotoscursos/foto6.jpg" // Tell webpack this JS file uses this image
import foto7 from "../images/fotoscursos/foto7.jpg" // Tell webpack this JS file uses this image
import foto8 from "../images/fotoscursos/foto8.jpg" // Tell webpack this JS file uses this image
import foto9 from "../images/fotoscursos/foto9.jpg" // Tell webpack this JS file uses this image
import foto10 from "../images/fotoscursos/foto10.jpg" // Tell webpack this JS file uses this image
import foto11 from "../images/fotoscursos/foto11.jpg" // Tell webpack this JS file uses this image
import foto12 from "../images/fotoscursos/foto12.jpg" // Tell webpack this JS file uses this image
import foto13 from "../images/fotoscursos/foto13.jpg" // Tell webpack this JS file uses this image
import foto14 from "../images/fotoscursos/foto14.jpg" // Tell webpack this JS file uses this image
import foto15 from "../images/fotoscursos/foto15.jpg" // Tell webpack this JS file uses this image
import Footerabout from "../components/footer-about"
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/rtouret.jpg" // Tell webpack this JS file uses this image
import pal13 from "../images/palestrantes/imaculada.jpg" // Tell webpack this JS file uses this image
import sponsor1 from "../images/logos/about/logo1.png" // Tell webpack this JS file uses this image
import sponsor2 from "../images/logos/about/logo2.png" // Tell webpack this JS file uses this image
import sponsor3 from "../images/logos/about/logo3.png" // Tell webpack this JS file uses this image
import sponsor4 from "../images/logos/about/logo4.png" // Tell webpack this JS file uses this image
import sponsor5 from "../images/logos/about/logo5.png" // Tell webpack this JS file uses this image
import sponsor6 from "../images/logos/about/logo6.png" // Tell webpack this JS file uses this image
import sponsor7 from "../images/logos/about/logo7.png" // Tell webpack this JS file uses this image
import wqlogo from "../images/logos/WQlogo.png" // Tell webpack this JS file uses this image

import marker from "../images/logos/about/logo-local.png" // Tell webpack this JS file uses this image

import GoogleMap from '../components/GoogleMap'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};


const wqmatadouros = () => (
  <Layout>


  <div id="wq-section">
    <div className="wrapper">
      <div className="col-1st">
        <div className="titulo inter-bold">
          <p>Formação de auditor de bem-estar animal</p>
        </div>
        <div className="texto inter-regular opacity">
          <p>Uma parceria entre a FarmIN e a Welfare Quality Network e Animal Welfare Indicators </p>

        </div>

      </div>
    </div>
  </div>
  <div id="wq-section-2">
    <div className="wrapper">
      <div className="col-1st">
      <div className="titulo inter-bold">
          <p>Âmbito</p>
        </div>
      <div className="texto inter-regular opacity">

      <p>Desde 2020 que a farmIN faz cursos de auditor de bem-estar animal nas diversas espécies de produção animal. Detemos uma parceria exclusiva onde as nossas formações são certificadas e aprovadas pela Welfare Quality Network, IRTA e Animal Welfare Indicators.<br></br>
         </p>
      </div>
      <br></br>
    
      </div>
      <div className="col-2st">

      </div>
    </div>
  </div>

  <div id="wq-section-3">
  <div className="titulo inter-bold">
          <p> Cursos disponíveis </p>
        </div>
        <div className="texto inter-regular opacity">
          <p>UEstas formações direcionam-se para os técnicos responsáveis pelo bem-estar nas empresas que pretendem certificar-se para o mesmo ou para empresas de certificação. <br></br>
          Com esta formação pode tornar-se auditor de bem-estar interno (controlo interno do bem-estar na exploração) ou externo (auditoria de certificação).<br></br>

Informações acerca da certificação de bem-estar animal WELFAIR <a href="animalwelfair.com" target="_blank">aqui. </a> </p>

        </div>
    <div className="wrapper">
  

     <div className="col1">
    
     <div className="card">
        <div className="row2">
        <div className="titulo-masterclass inter-bold">
           Masterclass
            </div>
            <div className="titulo-masterclass opacity inter-regular">
            Curso de auditor de bem-estar em bovinos de carne
            </div>
          </div>
          <div className="row1">
       
       
          </div>
        

        
        </div>
       
     </div>
     <div className="col1">
    
     <div className="card">
        <div className="row2">
        <div className="titulo-masterclass inter-bold">
           Masterclass
            </div>
            <div className="titulo-masterclass opacity inter-regular">
            Curso de auditor de bem-estar em bovinos de leite
            </div>
          </div>
          <div className="row1">
          {/* <div className="foto">
          <img className="imagem" src={pal6} alt="" />
            
          </div> */}
            {/* <div className="nome inter-bold">
            George Stilwell
            </div> */}
          </div>
        

        
        </div>
        
     </div>
     <div className="col1">
     {/* <Link to="/WQ-engorda-suinos/"> */}
     <div className="card">
        <div className="row2">
        <div className="titulo-masterclass inter-bold">
           Masterclass
            </div>
            <div className="titulo-masterclass opacity inter-regular">
            Curso de auditorr de bem-estar em matadouro de bovinos
            </div>
          </div>
          <div className="row1">
          {/* <div className="foto">
          <img className="imagem" src={pal2} alt="" />
            
          </div>
            <div className="nome inter-bold">
            Antoni Dalmau Bueno
            </div> */}
          </div>
        

        
        </div>
        {/* </Link> */}
     </div>
     <div className="col1">
     {/* <Link to="/WQ-matadouro-suinos/"> */}
        <div className="card">
        <div className="row2">
        <div className="titulo-masterclass inter-bold">
           Masterclass
            </div>
            <div className="titulo-masterclass opacity inter-regular">
            Curso de auditor de bem-estar em matadouro de suínos

            </div>
          </div>
          <div className="row1">
          {/* <div className="foto">
          <img className="imagem" src={pal3} alt="" />
            
          </div>
            <div className="nome inter-bold">
            Quim Pallisera Lloveras
            </div> */}
          </div>
        

        
        </div>
        {/* </Link> */}
     </div>
     
    </div>

    <div className="wrapper">
  
    <div className="col1">
     {/* <Link to="/WQ-matadouro-suinos/"> */}
        <div className="card">
        <div className="row2">
        <div className="titulo-masterclass inter-bold">
           Masterclass
            </div>
            <div className="titulo-masterclass opacity inter-regular">
            Curso de auditor de bem-estar em engorda de suínos

            </div>
          </div>
          <div className="row1">
          {/* <div className="foto">
          <img className="imagem" src={pal3} alt="" />
            
          </div>
            <div className="nome inter-bold">
            Quim Pallisera Lloveras
            </div> */}
          </div>
        

        
        </div>
        {/* </Link> */}
     </div>
     <div className="col1">
     {/* <Link to="/WQ-matadouro-suinos/"> */}
        <div className="card">
        <div className="row2">
        <div className="titulo-masterclass inter-bold">
           Masterclass
            </div>
            <div className="titulo-masterclass opacity inter-regular">
            Curso de auditor de bem-estar em porcas reprodutoras e leitões

            </div>
          </div>
          <div className="row1">
          {/* <div className="foto">
          <img className="imagem" src={pal3} alt="" />
            
          </div>
            <div className="nome inter-bold">
            Quim Pallisera Lloveras
            </div> */}
          </div>
        

        
        </div>
        {/* </Link> */}
     </div>
     <div className="col1">
     {/* <Link to="/WQ-matadouro-suinos/"> */}
        <div className="card">
        <div className="row2">
        <div className="titulo-masterclass inter-bold">
           Masterclass
            </div>
            <div className="titulo-masterclass opacity inter-regular">
            Curso de auditor de bem-estar em borregos de engorda

            </div>
          </div>
          <div className="row1">
          {/* <div className="foto">
          <img className="imagem" src={pal3} alt="" />
            
          </div>
            <div className="nome inter-bold">
            Quim Pallisera Lloveras
            </div> */}
          </div>
        

        
        </div>
        {/* </Link> */}
     </div>
     <div className="col1">
     {/* <Link to="/WQ-matadouro-suinos/"> */}
        <div className="card">
        <div className="row2">
        <div className="titulo-masterclass inter-bold">
           Masterclass
            </div>
            <div className="titulo-masterclass opacity inter-regular">
            Curso de auditor de bem-estar em pequenos ruminantes leiteiros

            </div>
          </div>
          <div className="row1">
          {/* <div className="foto">
          <img className="imagem" src={pal3} alt="" />
            
          </div>
            <div className="nome inter-bold">
            Quim Pallisera Lloveras
            </div> */}
          </div>
        

        
        </div>
        {/* </Link> */}
     </div>

   
     
    </div>


    <div className="wrapper">
  
  <div className="col1">
   {/* <Link to="/WQ-matadouro-suinos/"> */}
      <div className="card">
      <div className="row2">
      <div className="titulo-masterclass inter-bold">
         Masterclass
          </div>
          <div className="titulo-masterclass opacity inter-regular">
          Curso de auditor de bem-estar em perus

          </div>
        </div>
        <div className="row1">
        {/* <div className="foto">
        <img className="imagem" src={pal3} alt="" />
          
        </div>
          <div className="nome inter-bold">
          Quim Pallisera Lloveras
          </div> */}
        </div>
      

      
      </div>
      {/* </Link> */}
   </div>
   <div className="col1">
   {/* <Link to="/WQ-matadouro-suinos/"> */}
      <div className="card">
      <div className="row2">
      <div className="titulo-masterclass inter-bold">
         Masterclass
          </div>
          <div className="titulo-masterclass opacity inter-regular">
          Curso de auditor de bem-estar em galinhas poedeiras

          </div>
        </div>
        <div className="row1">
        {/* <div className="foto">
        <img className="imagem" src={pal3} alt="" />
          
        </div>
          <div className="nome inter-bold">
          Quim Pallisera Lloveras
          </div> */}
        </div>
      

      
      </div>
      {/* </Link> */}
   </div>
   <div className="col1">
   {/* <Link to="/WQ-matadouro-suinos/"> */}
      <div className="card">
      <div className="row2">
      <div className="titulo-masterclass inter-bold">
         Masterclass
          </div>
          <div className="titulo-masterclass opacity inter-regular">
          Curso de auditor de bem-estar em exploração de frangos

          </div>
        </div>
        <div className="row1">
        {/* <div className="foto">
        <img className="imagem" src={pal3} alt="" />
          
        </div>
          <div className="nome inter-bold">
          Quim Pallisera Lloveras
          </div> */}
        </div>
      

      
      </div>
      {/* </Link> */}
   </div>
   <div className="col1">
   {/* <Link to="/WQ-matadouro-suinos/"> */}
      <div className="card">
      <div className="row2">
      <div className="titulo-masterclass inter-bold">
         Masterclass
          </div>
          <div className="titulo-masterclass opacity inter-regular">
          Curso de auditor de bem-estar em matadouro de frangos

          </div>
        </div>
        <div className="row1">
        {/* <div className="foto">
        <img className="imagem" src={pal3} alt="" />
          
        </div>
          <div className="nome inter-bold">
          Quim Pallisera Lloveras
          </div> */}
        </div>
      

      
      </div>
      {/* </Link> */}
   </div>

 
   
  </div>


  <div className="wrapper">
  
  <div className="col1">
   {/* <Link to="/WQ-matadouro-suinos/"> */}
      <div className="card">
      <div className="row2">
      <div className="titulo-masterclass inter-bold">
         Masterclass
          </div>
          <div className="titulo-masterclass opacity inter-regular">
          Curso de auditor de bem-estar em coelhos (reprodutoras e engorda)

          </div>
        </div>
        <div className="row1">
        {/* <div className="foto">
        <img className="imagem" src={pal3} alt="" />
          
        </div>
          <div className="nome inter-bold">
          Quim Pallisera Lloveras
          </div> */}
        </div>
      

      
      </div>
      {/* </Link> */}
   </div>
   <div className="col1">
   {/* <Link to="/WQ-matadouro-suinos/"> */}
      <div className="card">
      <div className="row2">
      <div className="titulo-masterclass inter-bold">
         Masterclass
          </div>
          <div className="titulo-masterclass opacity inter-regular">
          Curso de auditor de bem-estar em matadouro de pequenos ruminantes

          </div>
        </div>
        <div className="row1">
        {/* <div className="foto">
        <img className="imagem" src={pal3} alt="" />
          
        </div>
          <div className="nome inter-bold">
          Quim Pallisera Lloveras
          </div> */}
        </div>
      

      
      </div>
      {/* </Link> */}
   </div>
   <div className="col1">
   
   </div>
   <div className="col1">

   </div>

 

 
   
  </div>
  </div>

  <Footerabout/>
  </Layout>
)

export default wqmatadouros
